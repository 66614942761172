import { render, staticRenderFns } from "./Artifacts.vue?vue&type=template&id=3d22b961&scoped=true&"
import script from "./Artifacts.vue?vue&type=script&lang=js&"
export * from "./Artifacts.vue?vue&type=script&lang=js&"
import style0 from "./Artifacts.vue?vue&type=style&index=0&id=3d22b961&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d22b961",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VFadeTransition,VIcon,VItem,VItemGroup,VProgressCircular,VRow,VScaleTransition,VTooltip,VWindow,VWindowItem})
